import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../contact/Contact.scss";
import CustNavbar from "../navbar/CustNavbar";
import { ReactLenis, useLenis } from "lenis/react";
import axios from "axios";

function Contact() {
	const sendemail = (evt) => {
		const req = {
			to: "demo@gmail.com",
			message: evt.target.message.value,
			email: evt.target.email.value,
			company: evt.target.company.value,
			phone: evt.target.phone.value,
			name: evt.target.name.value,
		};
		axios
			.post("/api/sendemail/", req, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				alert("We will reach out to you soon!");
				clearForm();
			})
			.catch((err) => {
				alert("OOPS.. Something Failed. Please drop an email to our address.");
			});
	};

	// State to hold form values
	const [formData, setFormData] = useState({
		email: "",
		message: "",
		company: "",
		phone: "",
		name: "",
	});

	// Handle input change
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	// Clear the form
	const clearForm = () => {
		setFormData({
			email: "",
			message: "",
			company: "",
			phone: "",
			name: "",
		});
	};

	return (
		<ReactLenis root>
			<div className="main">
				<CustNavbar
					className="nav"
					style={{
						backgroundColor: "#ffffff",
						color: "#000000",
						backgroundOpacity: "1",
					}}
				/>
				<div className="contactus">
					<h1 className="contactHeader">Contact Us</h1>
					<Container fluid className="contactSection">
						<Row className="sectionRow">
							<Col md={6} sm={12} className="subsection subleft">
								<div className="leftsection">We would love to work with you!</div>
								<div className="contactinfo">
									<p>27 Old Gloucester Street, London, United Kingdom, WC1N 3AX</p>
									<p>
										<a href="mailto:processing@cardpaymentz.com">processing@cardpaymentz.com</a>
									</p>
									<p className="tel">
										<a href="tel:+44-333-339-4167">+44-333-339-4167</a>
									</p>
									<h3>For any Questions</h3>
								</div>
							</Col>
							<Col md={6} sm={12} className="subsection subright">
								<div className="rightsection">
									<h2>We are very close to each other...</h2>
									<h4>All that's left to do is fill out the form to partner with us</h4>
									<form
										className="form"
										onSubmit={(event) => {
											event.preventDefault();
											sendemail(event);
										}}
									>
										<div className="formpart">
											<input placeholder="Name" type="text" name="name" value={formData.name} onChange={handleChange} style={{ width: "46%" }}></input>
											<input placeholder="Company" type="text" name="company" value={formData.company} onChange={handleChange} style={{ width: "46%" }}></input>
										</div>
										<div className="formpart">
											<input placeholder="Phone" type="text" name="phone" value={formData.phone} onChange={handleChange} style={{ width: "46%" }}></input>
											<input placeholder="Email" type="text" value={formData.email} onChange={handleChange} name="email" style={{ width: "46%" }}></input>
										</div>
										<textarea
											placeholder="Message"
											type="text"
											name="message"
											value={formData.message}
											onChange={handleChange}
											style={{ width: "100%" }}
											className="messageIp"
										></textarea>

										<Button type="submit">Submit</Button>
									</form>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</ReactLenis>
	);
}

export default Contact;
