import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./Footer.scss";
import icon from "../assets/iconwithtxt.png";
import logo from "../assets/Logo.png";
function Footer({ style }) {
  return (
    <Container fluid className="footer" style={style}>
      <Row className="footerLogo">
        <img src={logo} className="footerImg" />
      </Row>
      <Row className="footerCardRow">
        <Col md={4} className="footerCard">
          Payments
        </Col>
        <Col md={4} className="footerCard">
          Made
        </Col>
        <Col md={4} className="footerCard">
          Simple
        </Col>
      </Row>
      <Row className="footerClick">
        <Col md={2} className="footerClickElement">
          <a href="/solutions">Solutions</a>
        </Col>
        <Col md={2} className="footerClickElement">
          <a href="/developers">Developer</a>
        </Col>
        <Col md={2} className="footerClickElement">
          <a href="/aboutus">About Us</a>
        </Col>
        <Col md={2} className="footerClickElement">
          <a href="/insights">Insight</a>
        </Col>
        <Col md={2} className="footerClickElement">
          <a href="/contact">Talk to us</a>
        </Col>
      </Row>
      <Row className="footerClick footerClick2">
        <Col md={4} className="footerClickElement">
          <a href="/Terms & Conditions.pdf">Terms & Conditions</a>
        </Col>
        <Col md={4} className="footerClickElement">
          <a href="/FAQs.pdf">FAQ's</a>
        </Col>
        <Col md={4} className="footerClickElement">
          <a href="/Privacy Policy.pdf">Privacy Policy</a>
        </Col>
      </Row>
      <Row className="footerAddr">
        <Col><p>Card Paymentz Limited, registered under the laws of England and Wales with Company Number 13582655, with its registered office at 27 Old Gloucester Street, London, United Kingdom, WC1N 3AX</p></Col>
      </Row>
    </Container>
  );
}

export default Footer;
