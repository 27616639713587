import { useCallback, useState, useEffect, useRef, createRef } from "react";
import CustNavbar from "../navbar/CustNavbar";
import { ReactLenis, useLenis } from "lenis/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/src/ScrollTrigger";
import { Container, Row, Col, Button } from "react-bootstrap";
import logo1 from "../assets/scrollLogo/logo_1.png";
import logo2 from "../assets/scrollLogo/logo_2.png";
import logo3 from "../assets/scrollLogo/logo_3.png";
import logo4 from "../assets/scrollLogo/logo_4.png";
import logo5 from "../assets/scrollLogo/logo_5.png";
import logo6 from "../assets/scrollLogo/logo_6.png";
import logo7 from "../assets/scrollLogo/logo_7.png";
import logo8 from "../assets/scrollLogo/logo_8.png";
import logo9 from "../assets/scrollLogo/logo_9.png";
import logo10 from "../assets/scrollLogo/logo_10.png";
import logo from "../assets/icononly.png";
import payment from "../assets/Payment.mp4";
import "./Main.scss";
import SlideContent from "../slideContent/SlideContent";
import Footer from "../footer/Footer";
gsap.registerPlugin(ScrollTrigger);
function Main() {
  function importAll(r) {
    let images = {};
    r.keys().map((item) => {
      images[item.replace("../assets/scrollLogos", "")] = r(item);
    });
    return images;
  }

  // const images = importAll(require.context("../assets/scrollLogo/l", false, " /.(png|jpe?g|svg)$/"));

  //<img src={images["0.png"]} />;

  const [navbarBackgroundColor, setNavbarBackgroundColor] = useState("#fff");
  const [navbarTextColor, setNavbarTextColor] = useState("#2d2e2e");
  const [backgroundOpacity, setbBackgroundOpacity] = useState("0.05");
  const [backColor, setBackColor] = useState("rgba(255,255,255)");
  const [floatDivStyle, setFloatDivStyle] = useState({ position: "relative" });
  const [textshopStyle, setTextshopStyle] = useState({ opacity: 1 });
  const [darkBcg, setDarkbcg] = useState(false);
  const changeColor = () => {
    //scroll background change
    if (isElementOutViewport(document.getElementById("blackBcg")) !== 0) {
      var opacity = isElementOutViewport(document.getElementById("blackBcg"));
      setNavbarBackgroundColor("rgba(33,33,33," + opacity + ")");
      setBackColor("rgba(24, 24, 24," + opacity + ")");
      setNavbarTextColor("rgba(255,255,255," + opacity + ")");
      setbBackgroundOpacity(
        isElementOutViewport(document.getElementById("blackBcg"))
      );
      setDarkbcg(true);
    } else {
      setNavbarBackgroundColor("#fff");
      setNavbarTextColor("#000");
      setBackColor("rgb(255, 255, 255)");
      setDarkbcg(false);
    }
    //scroll text fade
    var color;
    if (darkBcg) {
      color = "rgba(255,255,255,";
      var textRect = document
        .getElementById("textshop")
        .getBoundingClientRect();
      var textBelow = document
        .getElementById("belowTextShop")
        .getBoundingClientRect();
      if (textRect.top < 383 && textBelow.top < 220) {
        setTextshopStyle({
          color: color + (1 - (220 - textBelow.top) / 250) + ")",
        });
        if (1 - (220 - textBelow.top) / 250 < 0) {
          setTextshopStyle({ visibility: "hidden" });
        }
      } else {
        setTextshopStyle({ color: color + 1 + ")" });
      }
    } else {
      setTextshopStyle({ color: "rgba(0,0,0," + 1 + ")" });
    }
  };

  function isElementOutViewport(el) {
    var flexRect = el.getBoundingClientRect();
    if (flexRect.bottom > 45 && flexRect.top < 150)
      if (150 - flexRect.top > 0 && 150 - flexRect.top < 100)
        return (150 - flexRect.top) / 100;
      else if (150 - flexRect.bottom > 0) {
        return 1 - (150 - flexRect.bottom) / 100;
      } else return 1;
    else return 0;
  }

  useEffect(() => {
    gsap.to("#leftGsap", {
      scrollTrigger: {
        trigger: ".rowFloatCards",
        start: "top 30%",
        end: "bottom 30%",
        // markers: true,
        scrub: true,
        toggleActions: "play reverse play reverse",
        onUpdate: (self) => {
          const progress = self.progress;
          document.querySelector("#leftGsap").style.transform = ` translate3d(${
            8 - progress * 8
          }vw, ${
            42 - progress * 42
          }vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(${
            -7 + progress * 7
          }deg) skew(0deg, 0deg)`;
          document.querySelector(
            "#midGsap"
          ).style.transform = ` translate3d(0vw,${
            37 - progress * 37
          }vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
          document.querySelector(
            "#rightGsap"
          ).style.transform = ` translate3d(${-8 + progress * 8}vw, ${
            42 - progress * 42
          }vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(${
            7 - progress * 7
          }deg) skew(0deg, 0deg)`;
        },
      },
    });
  }, []);

  window.addEventListener("scroll", changeColor);

  return (
    <ReactLenis root>
      <div
        className="main"
        id="main"
        style={{
          backgroundColor: backColor,
          color: navbarTextColor,
          backgroundOpacity: backgroundOpacity,
        }}
      >
        <CustNavbar
          className="nav"
          style={{
            backgroundColor: navbarBackgroundColor,
            color: navbarTextColor,
            backgroundOpacity: backgroundOpacity,
          }}
        />
        <div className="content">
          <Container fluid className="cards" id="cards">
            <Row>
              <Col md={5} className="card">
                <div className="innerCard">
                  {" "}
                  <h1>Your Own Payment Partner</h1>
                  <h4>Payment Methods for your Business</h4>
                </div>
              </Col>
              <Col md={7} className="card">
                <div className="innerCard videoCard">
                  <video muted={true} autoPlay={true} loop>
                    {" "}
                    <source src={payment} type="video/mp4" />
                  </video>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="blackBcg" id="blackBcg">
            <Container fluid className="scrollLogoDiv" id="scrollLogoDiv">
              <h2>Payment Methods for your Business</h2>
              <div className="scrollLogo">
                <div class="m-scroll">
                  <span>
                    <img src={logo1} alt="" />
                  </span>
                  <span>
                    <img src={logo2} alt="" style={{ width: "3vw" }} />
                  </span>
                  <span>
                    <img src={logo3} alt="" />
                  </span>
                  <span>
                    <img src={logo4} alt="" />
                  </span>
                  <span>
                    <img
                      src={logo5}
                      style={{
                        filter:
                          "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(3659%) hue-rotate(166deg) brightness(101%) contrast(111%)",
                      }}
                      alt=""
                    />
                  </span>
                  <span>
                    <img src={logo6} alt="" />
                  </span>
                  var
                  <span>
                    <img src={logo7} alt="" />
                  </span>
                  <span>
                    <img src={logo8} alt="" />
                  </span>
                  <span>
                    <img src={logo9} alt="" />
                  </span>
                  <span>
                    <img src={logo10} alt="" />
                  </span>
                </div>
              </div>
            </Container>
            <Container className="textshop" id="textshop" style={textshopStyle}>
              Accept Payments the way your user wants to pay
            </Container>
            <Container
              fluid
              className="floatCardsDiv"
              id="belowTextShop"
              style={{ floatDivStyle }}
            >
              <Row className="rowFloatCards" id="rowFloatCards">
                <Col md={4} className="left card" id="leftGsap">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M352 256c0 22.2-1.2 43.6-3.3 64l-185.3 0c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64l185.3 0c2.2 20.4 3.3 41.8 3.3 64zm28.8-64l123.1 0c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64l-123.1 0c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32l-116.7 0c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0l-176.6 0c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0L18.6 160C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192l123.1 0c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64L8.1 320C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6l176.6 0c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352l116.7 0zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6l116.7 0z" />
                  </svg>
                  <h3>
                    Global
                    <br />
                    Coverage
                  </h3>
                  <p>
                    Our extensive payment network helps you reach your
                    borderless ambitions
                  </p>
                </Col>
                <Col md={4} className="mid card" id="midGsap">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                  </svg>
                  <h3>
                    Seamless
                    <br />
                    Integration
                  </h3>
                  <p>
                    Our API offers a suite of powerful tools that let you manage
                    and process seamless payments
                  </p>
                </Col>
                <Col md={4} className="right card" id="rightGsap">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M144 144l0 48 160 0 0-48c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192l0-48C80 64.5 144.5 0 224 0s144 64.5 144 144l0 48 16 0c35.3 0 64 28.7 64 64l0 192c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 256c0-35.3 28.7-64 64-64l16 0z" />
                  </svg>
                  <h3>
                    Secured
                    <br />
                    Payments
                  </h3>
                  <p>
                    We implement cutting-edge anti-fraud and risk management
                    tools to protect your revenues and your reputation.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <SlideContent />
          <div className="paynow">
            <h1 className="paynowtext">
              Payments <br />
              made simple.
            </h1>
            {/* <div className="paynowbuttons">
							<Button className="button1">Try for Free</Button>
							<Button className="button2">Book a Demo</Button>
						</div> */}
          </div>
          <Footer style={{ borderRadius: "64px" }} />
        </div>
      </div>
    </ReactLenis>
  );
}

export default Main;
